import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import "./Overview.css";
import { API } from "aws-amplify";
import { calculateTotals } from "../../../lib/calculateTotals";
import { isMobile } from "react-device-detect";
import App from "./App";
import { Chart } from "react-google-charts";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";

export default function Overview() {
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadBudgetItems() {
    return API.get("budget", "/list-items");
  }

  function renderBudgetItemsList(budgetItems) {
    if (isMobile) {
      require("./custom.css");
    }

    var objIndex;

    let budgetTotals = [];

    let budgetTotalPercentages = [];

    let incomeSubtypes = [];
    let savingSubtypes = [];
    let expenseSubtypes = [];

    let budgetLineTotal = {
      index: 1,
      typeTotal: "Income",
      amountWeek: 0,
      amountFortnight: 0,
      amountMonth: 0,
      amountQuarter: 0,
      amountYear: 0,
    };
    budgetTotals.push(budgetLineTotal);

    budgetLineTotal = {
      index: 2,
      typeTotal: "Savings",
      amountWeek: 0,
      amountFortnight: 0,
      amountMonth: 0,
      amountQuarter: 0,
      amountYear: 0,
    };
    budgetTotals.push(budgetLineTotal);

    budgetLineTotal = {
      index: 3,
      typeTotal: "Expenses",
      amountWeek: 0,
      amountFortnight: 0,
      amountMonth: 0,
      amountQuarter: 0,
      amountYear: 0,
    };
    budgetTotals.push(budgetLineTotal);

    let percentOfIncomeSubtype = {
      labels: [],
      data: [],
    };

    var percentOfSavingsSubtype = {
      labels: [],
      data: [],
    };

    var percentOfExpensesSubtype = {
      labels: [],
      data: [],
    };

    var dataIncome = [];
    var dataSavings = [];
    var dataExpenses = [];
    dataIncome.push(["Subtype", "Amount"]);
    dataSavings.push(["Subtype", "Amount"]);
    dataExpenses.push(["Subtype", "Amount"]);

    for (let budgetItem of budgetItems) {
      let index;
      let budgetTotalsIndex;
      let typeText;
      let accessArray;

      if (budgetItem.itemType === "income") {
        index = 1;
        typeText = "Income";
        accessArray = incomeSubtypes;
      } else if (budgetItem.itemType === "savings") {
        index = 2;
        typeText = "Savings";
        accessArray = savingSubtypes;
      } else if (budgetItem.itemType === "expense") {
        index = 3;
        typeText = "Expenses";
        accessArray = expenseSubtypes;
      } else {
        budgetItem.itemType = "income";
        index = 1;
        typeText = "Income";
        accessArray = incomeSubtypes;
      }

      // Populate array in the format
      // index = 1
      // type = income
      // amountWeek = 100
      // amountFortnight = 200
      // amountMonth = 400
      // amountQuarter = 1300
      // amountYear = 5200
      if (
        budgetTotals.some(
          (budgetLineTotal) => budgetLineTotal.typeTotal === typeText
        )
      ) {
        budgetTotalsIndex = budgetTotals.findIndex(
          (budgetLineTotal) => budgetLineTotal.typeTotal === typeText
        );
        budgetTotals[budgetTotalsIndex].amountWeek =
          budgetTotals[budgetTotalsIndex].amountWeek +
          parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "WEEK")
          );
        budgetTotals[budgetTotalsIndex].amountFortnight =
          budgetTotals[budgetTotalsIndex].amountFortnight +
          parseInt(
            calculateTotals(
              budgetItem.amount,
              budgetItem.frequency,
              "FORTNIGHT"
            )
          );
        budgetTotals[budgetTotalsIndex].amountMonth =
          budgetTotals[budgetTotalsIndex].amountMonth +
          parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "MONTH")
          );
        budgetTotals[budgetTotalsIndex].amountQuarter =
          budgetTotals[budgetTotalsIndex].amountQuarter +
          parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "QUARTER")
          );
        budgetTotals[budgetTotalsIndex].amountYear =
          budgetTotals[budgetTotalsIndex].amountYear +
          parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR")
          );
      } else {
        let budgetLineTotal = {
          index: index,
          typeTotal: typeText,
          amountWeek: parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "WEEK")
          ),
          amountFortnight: parseInt(
            calculateTotals(
              budgetItem.amount,
              budgetItem.frequency,
              "FORTNIGHT"
            )
          ),
          amountMonth: parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "MONTH")
          ),
          amountQuarter: parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "QUARTER")
          ),
          amountYear: parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR")
          ),
        };
        if (typeText === "Income") {
          budgetTotals.unshift(budgetLineTotal);
        } else {
          budgetTotals.push(budgetLineTotal);
        }
      }

      if (
        accessArray.some(
          (subTypeItem) => subTypeItem.subtype === budgetItem.itemSubType
        )
      ) {
        objIndex = accessArray.findIndex(
          (subTypeItem) => subTypeItem.subtype === budgetItem.itemSubType
        );
        accessArray[objIndex].totalAmount =
        accessArray[objIndex].totalAmount +
          parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR")
          );
      } else {
        let subTypeItem = {
          subtype: budgetItem.itemSubType,
          totalAmount: parseInt(
            calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR")
          ),
        };
        accessArray.push(subTypeItem);
      }
      
    }

    // Work out leftover amounts, push into budgetTotals array
    let budgetLeftOverLineTotal = {
      index: "4",
      typeTotal: "Leftover",
      amountWeek:
        budgetTotals[0].amountWeek -
        budgetTotals[1].amountWeek -
        budgetTotals[2].amountWeek,
      amountFortnight:
        budgetTotals[0].amountFortnight -
        budgetTotals[1].amountFortnight -
        budgetTotals[2].amountFortnight,
      amountMonth:
        budgetTotals[0].amountMonth -
        budgetTotals[1].amountMonth -
        budgetTotals[2].amountMonth,
      amountQuarter:
        budgetTotals[0].amountQuarter -
        budgetTotals[1].amountQuarter -
        budgetTotals[2].amountQuarter,
      amountYear:
        budgetTotals[0].amountYear -
        budgetTotals[1].amountYear -
        budgetTotals[2].amountYear,
    };
    budgetTotals.push(budgetLeftOverLineTotal);

    budgetTotals.forEach((type) => {
      type["amountWeekPretty"] = parseFloat(
        type["amountWeek"]
      ).toLocaleString();
      type["amountFortnightPretty"] = parseFloat(
        type["amountFortnight"]
      ).toLocaleString();
      type["amountMonthPretty"] = parseFloat(
        type["amountMonth"]
      ).toLocaleString();
      type["amountQuarterPretty"] = parseFloat(
        type["amountQuarter"]
      ).toLocaleString();
      type["amountYearPretty"] = parseFloat(
        type["amountYear"]
      ).toLocaleString();
    });

    // Lets find where our things are in the index
    let incomeIndex = budgetTotals.findIndex(
      (budgetLineTotal) => budgetLineTotal.typeTotal === "Income"
    );
    let expensesIndex = budgetTotals.findIndex(
      (budgetLineTotal) => budgetLineTotal.typeTotal === "Expenses"
    );
    let savingsIndex = budgetTotals.findIndex(
      (budgetLineTotal) => budgetLineTotal.typeTotal === "Savings"
    );
    let leftoverIndex = budgetTotals.findIndex(
      (budgetLineTotal) => budgetLineTotal.typeTotal === "Leftover"
    );

    // Loop through each subtype and work stuff out for graphics
    let arrayList = [incomeSubtypes, savingSubtypes, expenseSubtypes]
    for (let i = 0; i < arrayList.length; i++){
      let currentArray = arrayList[i];
      let pushToPercents;
      let pushToData;
      let searchIndex;

      if (i === 0){
        pushToPercents = percentOfIncomeSubtype;
        pushToData = dataIncome;
        searchIndex = incomeIndex;
      }
      else if (i === 1){
        pushToPercents = percentOfSavingsSubtype;
        pushToData = dataSavings;
        searchIndex = savingsIndex;
      }
      else if (i === 2){
        pushToPercents = percentOfExpensesSubtype
        pushToData = dataExpenses;
        searchIndex = expensesIndex;
      }

      currentArray.forEach((itemSubType) => {
        // Work out percentages
        itemSubType["percentage"] = (
          (itemSubType.totalAmount / budgetTotals[searchIndex].amountYear) *
          100
        ).toFixed(2);
        itemSubType["percentageText"] = itemSubType.percentage + "%";
  
        // Used for graph
        pushToPercents.labels.push(itemSubType.subtype);
        pushToPercents.data.push(itemSubType.percentage);
  
        // Income Totals
        pushToData.push([itemSubType.subtype, itemSubType.totalAmount]);
      });
    }

    let savingsPercent = (
      (budgetTotals[savingsIndex].amountYear /
        budgetTotals[incomeIndex].amountYear) *
      100
    ).toFixed(2);
    let expensesPercent = (
      (budgetTotals[expensesIndex].amountYear /
        budgetTotals[incomeIndex].amountYear) *
      100
    ).toFixed(2);
    let leftoverPercent = (
      (budgetTotals[leftoverIndex].amountYear /
        budgetTotals[incomeIndex].amountYear) *
      100
    ).toFixed(2);


    //let percentOfIncomePieChart = {
    //      labels: ["Savings", "Expenses", "Leftover"],
    //data: [savingsPercent, expensesPercent, leftoverPercent]
    //}

    let budgetTypePercentagesTotal = {
      index: "0",
      Savings: savingsPercent + "%",
      Expenses: expensesPercent + "%",
      Leftover: leftoverPercent + "%",
    };
    budgetTotalPercentages.push(budgetTypePercentagesTotal);

    const dataOverall = [
      ["Type", "Amount"],
      ["Savings", parseInt(budgetTotals[savingsIndex].amountYear)],
      ["Expenses", parseInt(budgetTotals[expensesIndex].amountYear)],
      ["Leftover", parseInt(budgetTotals[leftoverIndex].amountYear)],
    ];

    //const dataIncome = incomeSubtypes.map(([subtype, totalAmount]) => ([subtype, totalAmount]));

    const optionsOverall = {
      title: "",
      legend: "bottom",
      pieSliceText: "none",
    };

    const optionsIncome = {
      title: "",
      legend: "bottom",
      pieSliceText: "none",
    };

    const optionsExpenses = {
      title: "",
      legend: "bottom",
      pieSliceText: "none",
    };

    const optionsSavings = {
      title: "",
      legend: "bottom",
      pieSliceText: "none",
    };

    return (
      <>
        <h3>Budget Total - Averaged over 12 months</h3>
        <div class="container-fluid">
          <App rowData={budgetTotals} />
        </div>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <LinkContainer to="/budget/income/new">
                <ListGroup.Item
                  action
                  className="py-3 text-nowrap text-truncate text-center"
                >
                  <BsPencilSquare size={17} />
                  <span className="ml-2 font-weight-bold"> Add Income</span>
                </ListGroup.Item>
              </LinkContainer>
            </div>
            <div class="col-sm">
              <LinkContainer to="/budget/savings/new">
                <ListGroup.Item
                  action
                  className="py-3 text-nowrap text-truncate text-center"
                >
                  <BsPencilSquare size={17} />
                  <span className="ml-2 font-weight-bold"> Add Saving</span>
                </ListGroup.Item>
              </LinkContainer>
            </div>
            <div class="col-sm">
              <LinkContainer to="/budget/expenses/new">
                <ListGroup.Item
                  action
                  className="py-3 text-nowrap text-truncate text-center"
                >
                  <BsPencilSquare size={17} />
                  <span className="ml-2 font-weight-bold"> Add Expense</span>
                </ListGroup.Item>
              </LinkContainer>
            </div>
          </div>
        </div>
        <hr />
        <h3 class="text-center">Income Split</h3>
        <div class="container">
          <div>
            <div class="container-fluid">
              <Chart
                chartType="PieChart"
                data={dataOverall}
                options={optionsOverall}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="container">
          <h3 class="text-center">Distribution</h3>
          <div class="row">
            <div class="col-sm">
              <div>
                <h3 class="text-center">Income</h3>
              </div>
              <div>
                <Chart
                  chartType="PieChart"
                  data={dataIncome}
                  options={optionsIncome}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div>Table</div>
            </div>
            <div class="col-sm">
              <div>
                <h3 class="text-center">Savings</h3>
              </div>
              <div>
                <Chart
                  chartType="PieChart"
                  data={dataSavings}
                  options={optionsSavings}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div>Table</div>
            </div>
            <div class="col-sm">
              <div>
                <h3 class="text-center">Expenses</h3>
              </div>
              <div>
                <Chart
                  chartType="PieChart"
                  data={dataExpenses}
                  options={optionsExpenses}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div>Table</div>
            </div>
          </div>
        </div>

        <div>
          <hr />
        </div>
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Budget Overview</h2>
        <ListGroup>
          {!isLoading && renderBudgetItemsList(budgetItems)}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Overview">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}
