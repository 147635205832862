import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../../../lib/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../../../components/LoaderButton";
import "./Asset.css";
import { useAppContext } from "../../../../lib/contextLib";
import ListGroup from "react-bootstrap/ListGroup";

export default function Asset() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAppContext();
  const [itemDate, setAssetDate] = useState("");
  const itemType = "asset";
  const [itemSubType, setItemSubType] = useState("");
  const [active, setAssetActive] = useState("");
  const [itemNote, setItemNote] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemSellPrice, setItemSellPrice] = useState("");
  const [budgetItem, setBudgetItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnChange = () => {
    setAssetActive(!active);
  };

  useEffect(() => {
    function loadBudgetItem() {
      return API.get("notes", `/portfolio/assets/${id}`);
    }

    async function onLoad() {
      try {
        const budgetItem = await loadBudgetItem();
        const { active, itemDate, itemSubType, itemNote, itemName, itemCode, itemQuantity, itemSellPrice } = budgetItem;


        setAssetActive(active);
        setAssetDate(itemDate);
        setItemSubType(itemSubType)
        setItemNote(itemNote);
        setItemName(itemName);
        setItemCode(itemCode);
        setItemQuantity(itemQuantity);
        setItemSellPrice(itemSellPrice);

        setBudgetItem(budgetItem);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return itemType.length > 0;
  }
  
  function saveBudgetItem(budgetItem) {
    return API.put("portfolio", `/assets/${id}`, {
      body: budgetItem
    });
  }
  
  async function handleSubmit(event) {
  
    event.preventDefault();
  
    setIsLoading(true);

    var assetTotalPrice = itemQuantity * itemSellPrice;
  
    try {
 
      await saveBudgetItem({
        active,
        itemDate,
        itemType,
        itemSubType,
        itemNote,
        itemName,
        itemCode,
        itemQuantity,
        itemSellPrice,
        itemTotalPrice: assetTotalPrice,
      });

      navigate("/portfolio/assets", { replace: true });

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteBudgetItem() {
    return API.del("portfolio", `/assets/${id}`);
  }
  
  async function handleInsert(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to insert new entry for this asset?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteBudgetItem();
        navigate("/portfolio/assets", { replace: true });
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteBudgetItem();
        navigate("/portfolio/assets", { replace: true });
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function renderBudgetItemsList() {

  return (
    <div className="Items">
      {budgetItem && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="itemDate" size="lg">
          <Form.Label>Date of Asset</Form.Label>
          <Form.Control 
            type="date" 
            name='itemDate'
            defaultValue={itemDate}
            placeholder={itemDate}
            onChange={(e) => setAssetDate(e.target.value)}
            />
        </Form.Group>
          <Form.Group controlId="subtypeOfItem" size="lg">
              <Form.Label>Type of Asset</Form.Label>
              <Form.Control
                autoFocus
                type="itemSubType"
                value={itemSubType}
                placeholder="Type of Asset"
                onChange={(e) => setItemSubType(e.target.value)}
              />
            </Form.Group>
          <Form.Group controlId="itemNote" size="lg">
              <Form.Label>Note</Form.Label>
              <Form.Control
                type="itemNote"
                value={itemNote}
                placeholder="Notes of asset"
                onChange={(e) => setItemNote(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="itemName" size="lg">
              <Form.Label>Asset Name</Form.Label>
              <Form.Control
                type="itemName"
                value={itemName}
                placeholder="Asset Name"
                onChange={(e) => setItemName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="itemCode" size="lg">
              <Form.Label>Asset Code</Form.Label>
              <Form.Control
                type="itemCode"
                value={itemCode}
                placeholder="Asset Code"
                onChange={(e) => setItemCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="itemQuantity" size="lg">
              <Form.Label>Asset Quantity</Form.Label>
              <Form.Control
                type="itemQuantity"
                value={itemQuantity}
                placeholder="Asset Quantity"
                onChange={(e) => setItemQuantity(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="itemSellPrice" size="lg">
              <Form.Label>Asset Sell Price</Form.Label>
              <Form.Control
                type="itemSellPrice"
                value={itemSellPrice}
                placeholder="Asset Sell Price"
                onChange={(e) => setItemSellPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="active" size="lg">
          <Form.Label>Asset Active</Form.Label>
          <Form.Check
            type="checkbox"
            id="active"
            label="Active"
            checked={active}
            onChange={handleOnChange}
          />
        </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Modify Snapshot
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="info"
            isLoading={isLoading}
            onClick={handleInsert}
            disabled={!validateForm()}
          >
            Insert New Snapshot
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete Snapshot
          </LoaderButton>
        </Form>
      )}
    </div>
  );
}

function renderLander() {
  return (
    <div className="lander">
      <h1>Making Budgets</h1>
      <p className="text-muted">A simple tool for making budgets</p>
    </div>
  );
}

function renderBudgetItems() {
  return (
    <div className="budgetItems">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Edit Asset</h2>
      <ListGroup>{!isLoading && renderBudgetItemsList()}</ListGroup>
    </div>
  );
}

return (
  <div className="Asset">
    {isAuthenticated ? renderBudgetItems() : renderLander()}
  </div>
);
}