import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";

import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import { calculateTotals } from "../../../lib/calculateTotals";

import "./Savings.css";

import App from "../../../components/tables/Table";
import * as TableColumns from "./Tables/columns.js";
import ItemListGroups from "../Common/ItemListGroups";

export default function Savings() {
  const [itemType] = useState("savings");
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    function loadBudgetItems() {
      return API.get("budget", `/list-items/${itemType}`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated, itemType]);

  function renderBudgetItemsList(budgetItems) {

    let savingTotals = [];
    let index = 0;
    let savingTotalsIndex;
    
    //let quarterAmount = 0;
  
    for (let budgetItem of budgetItems) {
      if (savingTotals.some(budgetLineTotal => budgetLineTotal.typeTotal === budgetItem.itemType)){
        savingTotalsIndex = savingTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === budgetItem.itemType);
        savingTotals[savingTotalsIndex].amountWeek = savingTotals[savingTotalsIndex].amountWeek + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "WEEK"))
        savingTotals[savingTotalsIndex].amountFortnight = savingTotals[savingTotalsIndex].amountFortnight + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "FORTNIGHT"))
        savingTotals[savingTotalsIndex].amountMonth = savingTotals[savingTotalsIndex].amountMonth + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "MONTH"))
        savingTotals[savingTotalsIndex].amountQuarter = savingTotals[savingTotalsIndex].amountQuarter + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "QUARTER"))
        savingTotals[savingTotalsIndex].amountYear = savingTotals[savingTotalsIndex].amountYear + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR"))
      } else {
        let budgetLineTotal = {
          "index": index,
          "typeTotal": budgetItem.itemType,
          "amountWeek": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "WEEK")),
          "amountFortnight": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "FORTNIGHT")),
          "amountMonth": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "MONTH")),
          "amountQuarter": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "QUARTER")),
          "amountYear": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR"))
        }
        savingTotals.push(budgetLineTotal);
      }
  
    }
  
    let yearBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
    {
        frequency.includes("YEAR") && item.push({itemId, itemSubType, description, amount, frequency});
        return item;
    }, []);
  
    let quarterBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
    {
        frequency.includes("QUARTER") && item.push({itemId, itemSubType, description, amount, frequency});
        return item;
    }, []);
  
    let monthBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
    {
        frequency.includes("MONTH") && item.push({itemId, itemSubType, description, amount, frequency});
        return item;
    }, []);
  
    let fortnightBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
    {
        frequency.includes("FORTNIGHT") && item.push({itemId, itemSubType, description, amount, frequency});
        return item;
    }, []);
  
    let weekBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
    {
        frequency.includes("WEEK") && item.push({itemId, itemSubType, description, amount, frequency});
        return item;
    }, []);

    return (
      <>
        <h3>Total - Averaged over 12 months</h3>

      <div style={{height: '100px'}}>
      <App columnData={TableColumns.savingTotalCols} rowData={savingTotals}/>
      </div>

        <LinkContainer to="/budget/savings/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Add a new saving</span>
          </ListGroup.Item>
        </LinkContainer>
        <ItemListGroups yearBudgetItems={yearBudgetItems} quarterBudgetItems={quarterBudgetItems} monthBudgetItems={monthBudgetItems} fortnightBudgetItems={fortnightBudgetItems} weekBudgetItems={weekBudgetItems}/>
        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Savings</h2>
        <ListGroup>{!isLoading && renderBudgetItemsList(budgetItems)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Savings">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}