import React, { useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import "./SubMenu.css";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function SubMenu() {
  const { isAuthenticated } = useAppContext();
  var filePath = window.location.pathname;

  if (filePath.endsWith("/")){
    if (filePath.endsWith("tools/")){
      filePath = filePath + "paycalculator";
    } else {
      filePath = filePath + "overview";
    }
  }

  useEffect(() => {

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      }
  
    onLoad();
  }, [isAuthenticated]);

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderSubMenu() {
    return (
      <div className="budgetItems">
      <Navbar collapseOnSelect bg="light" className="mb-3">
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-left">
      <Nav activeKey={filePath}>
              {window.location.pathname.includes("/budget") ? (
                <>
                <LinkContainer to="/budget/overview">
                  <Nav.Link>Overview</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/budget/income">
                  <Nav.Link>Income</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/budget/savings">
                  <Nav.Link>Savings</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/budget/expenses">
                  <Nav.Link>Expenses</Nav.Link>
                </LinkContainer>
                </>
              ) : (
                <>
                </>
              )}
               {window.location.pathname.includes("/portfolio") ? (
                <>
                <LinkContainer to="/portfolio/overview">
                  <Nav.Link>Overview</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/portfolio/assets">
                  <Nav.Link>Assets</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/portfolio/liabilities">
                  <Nav.Link>Liabilities</Nav.Link>
                </LinkContainer>
                </>
              ) : (
                <>
                </>
              )}
               {window.location.pathname.includes("/cards") ? (
                <>
                <LinkContainer to="/cards/overview">
                  <Nav.Link>Overview</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cards/debit">
                  <Nav.Link>Debit</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cards/credit">
                  <Nav.Link>Credit</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cards/rewards">
                  <Nav.Link>Rewards</Nav.Link>
                </LinkContainer>
                </>
              ) : (
                <>
                </>
              )}
               {window.location.pathname.includes("/tools") ? (
                <>
                <LinkContainer to="/tools/paycalculator">
                  <Nav.Link>Pay & Tax</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/tools/payrates">
                  <Nav.Link>Pay Rates</Nav.Link>
                </LinkContainer>
                </>
              ) : (
                <>
                </>
              )}
            </Nav>
            </Navbar.Collapse>
      </Navbar>
      </div>
    );
  }

  return (
    <div className="subMenu">
      {isAuthenticated ? renderSubMenu() : renderLander()}
    </div>
  );
}