import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import "./Liabilities.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

export default function Liabilities() {
  const [itemType] = useState("liability");
  const [liabilities, setliabilities] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    function loadAssets(liabilities) {
      return API.get("portfolio", `/list-items/liability`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const liabilities = await loadAssets();
        setliabilities(liabilities);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated, itemType]);



  function renderAssetsList(liabilities) {

    // Do Stuff

    //liabilities.forEach(asset => {
    //  asset["assetTotalPrice"] = asset.assetTotalPrice.toLocaleString(undefined, {maximumFractionDigits:5});
    //});

    //liabilities.forEach(asset => {
    //  asset["assetSellPricePretty"] = parseFloat(asset.assetSellPrice).toLocaleString(undefined, {maximumFractionDigits:5});
    //});


    // function priceFormatter(cell, row) {
    //   if (row.onSale) {
    //     return (
    //       <span>
    //         <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong>
    //       </span>
    //     );
    //   }
    //
    //   return (
    //
    //     <span>${ cell } </span>
    //   );
    // }
    
    // const sortType = [{
    //   dataField: "typeOfAsset",
    //   order: "asc"
    // }];

    // const cryptoColumns = [{
    //   dataField: "liabilityId",
    //   text: "liabilityId",
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
	//   hidden: true ,
    // }, {
    //   dataField: "itemCurrentDate",
    //   text: "Date",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   }
    // }, {
    //   dataField: "itemSubType",
    //   text: "Type",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
    // }, {
    //   dataField: "itemName",
    //   text: "Name",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
    // }, {
    //   dataField: "itemNote",
    //   text: "Note",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
    // }, {
    //   dataField: "liabilityStartDate",
    //   text: "Start Date",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
    // }, {
    //   dataField: "liabilityStartAmount",
    //   text: "Start Amount",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
    //   formatter: priceFormatter,
    //   sortFunc: (a, b, order) => {
    //     const numA = parseFloat(a.replace(/,/g,''), 10)
    //     const numB = parseFloat(b.replace(/,/g,''), 10)
    //     if (order === "asc") {
    //         return numB - numA;
    //     }
    //     return numA - numB;
    //   }
    // }, {
    //   dataField: "itemCurrentDate",
    //   text: "Current Date",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   }
    // }, {
    //   dataField: "itemCurrentAmount",
    //   text: "Current Amount",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   },
    //   formatter: priceFormatter,
    // }, {
    //   dataField: "liabilityActive",
    //   text: "Active",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: tableHeaders
    //   }
    // }];

    return (
      <>
        <hr></hr>

        <LinkContainer to="/portfolio/liabilities/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Add a new liability</span>
          </ListGroup.Item>
        </LinkContainer>
        <div class="text-center">
        <Container>
          <Row>
        {liabilities.map(({ itemId, itemCurrentDate, itemSubType, itemName, itemNote, itemCurrentAmount }) => (
          <Col md="4" xs="6">
          <LinkContainer key={itemId} to={`/portfolio/liabilities/${itemId}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {itemSubType.trim().split("\n")[0]}
              </span>
              <br></br>
              <span className="text-muted">
                {itemCurrentDate}
              </span>
              <br></br>
              <span className="text-muted">
                {itemName}
              </span>
              <br></br>
              <span className="text-muted">
                {itemNote}
              </span>
              <br></br>
              <span className="font-weight-bold">
              ${itemCurrentAmount}
              </span>
            </ListGroup.Item>
          </LinkContainer>                 
          </Col>
        ))}
        </Row>
        </Container>
        </div>
<div><hr></hr></div>
        TABLE, data=liabilities, columns = cryptoColumns

        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderAssets() {
    return (
      <div className="liabilities">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Liabilities</h2>
        <ListGroup>{!isLoading && renderAssetsList(liabilities)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Liabilities">
      {isAuthenticated ? renderAssets() : renderLander()}
    </div>
  );
}