import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";
import { onError } from "../../../lib/errorLib";
import "./NewAsset.css";
import { API } from "aws-amplify";

export default function NewAsset() {

  const [showhide, setShowhide]=useState('Cash');

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0,10);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const itemType = "asset";
  //const [active, setActive] = useState(false);
  const active = true;
  const [itemSubType, setItemSubType] = useState("Cash");
  const [itemCode, setItemCode] = useState("$");
  const [itemSellPrice, setItemSellPrice] = useState("1");

  const [itemNote, setItemNote] = useState("");
  const [itemDate, setItemDate] = useState(date);
  const [itemName, setItemName] = useState("");

  const [itemQuantity, setItemQuantity] = useState("");
  

  // const handleOnChange = () => {
  //   setActive(!active);
  // };

  function validateForm() {
    return itemNote.length > 0;
  }

  async function handleSubmit(event) {

    event.preventDefault();
  
    setIsLoading(true);

    let itemTotalPrice = itemQuantity * itemSellPrice;
  
    try {
 
      await createBudgetItem({ active, itemType, itemSubType, itemNote, itemDate, itemName, itemCode, itemQuantity, itemSellPrice, itemTotalPrice});
      navigate("/portfolio/assets", { replace: true });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createBudgetItem(budgetItem) {
    return API.post("portfolio", "/assets/add", {
      body: budgetItem
    });
  }

  const handleshowhide=(event)=>{
    const getvalue = event.target.value;
    setItemSubType(getvalue);

    setShowhide(getvalue);
    if (getvalue === "Cash") {
      setItemCode("$")
      setItemSellPrice("1")
    }

  }

  return (
    <div className="NewAsset">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Create new asset</h2>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="itemDate" size="lg">
          <Form.Label>Date of Asset</Form.Label>
          <Form.Control 
            type="date" 
            name='itemDate'
            defaultValue={itemDate}
            onChange={(e) => setItemDate(e.target.value)}
            />
      </Form.Group>
      <Form.Group controlId="itemSubType" size="lg">
          <Form.Label>Type of Asset</Form.Label>
          {/*<Form.Select aria-label="Default select example" onChange={(e) => setItemSubType(e.target.value)}>*/}
          <Form.Select aria-label="Default select example" onChange={(e) => (handleshowhide(e))}>
            <option value="Cash">Cash</option>
            <option value="Shares">Shares</option>
            <option value="Crypto">Crypto</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="itemName" size="lg">
          <Form.Label>Item Name</Form.Label>
          <Form.Control
            type="itemName"
            value={itemName}
            placeholder="Asset Name"
            onChange={(e) => setItemName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemNote" size="lg">
          <Form.Label>Item Description</Form.Label>
          <Form.Control
            type="itemNote"
            value={itemNote}
            placeholder="Item Note"
            onChange={(e) => setItemNote(e.target.value)}
          />
        </Form.Group>
        {showhide !== 'Cash' && (
        <Form.Group controlId="itemCode" size="lg">
          <Form.Label>Asset Code</Form.Label>
          <Form.Control
            type="itemCode"
            value={itemCode}
            placeholder="Asset Code"
            onChange={(e) => setItemCode(e.target.value)}
          />
                  </Form.Group>
          )
        }
        <Form.Group controlId="itemQuantity" size="lg">
          <Form.Label>Asset Quantity</Form.Label>
          <Form.Control
            type="itemQuantity"
            value={itemQuantity}
            placeholder="Asset Quantity"
            onChange={(e) => setItemQuantity(e.target.value)}
          />
        </Form.Group>
        {showhide !== 'Cash' && (
        <Form.Group controlId="itemSellPrice" size="lg">
          <Form.Label>Asset Sell Price</Form.Label>
          <Form.Control
            type="itemSellPrice"
            value={itemSellPrice}
            placeholder="Asset Sell Price"
            onChange={(e) => setItemSellPrice(e.target.value)}
          />
        </Form.Group>
        )}
        {/*
        <Form.Group controlId="active" size="lg">
          <Form.Label>Asset Active</Form.Label>
          <Form.Check
            type="checkbox"
            id="active"
            label="Active"
            checked={active}
            onChange={handleOnChange}
          />
        </Form.Group>
        */}
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}