import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import NumberFormat from 'react-number-format';

import { useAppContext } from "../../../lib/contextLib";
import * as TableColumns from "./Tables/columns.js";

import App from "../../../components/tables/Table";

export default function PayCalculator() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [salary, setSalary] = useState("50000");
  const [salaryFrequency, setSalaryFrequency] = useState("Annually");
  const [superannuationRate, setSuperAnnuationRate] = useState("10");
  const [workHoursPerWeek, setWorkHoursPerWeek] = useState("40");
  const [workDaysPerWeek, setWorkDaysPerWeek] = useState("5");

  useEffect(() => {

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
   
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);

  function renderPayCalculator() {

    var payTotals = [];
    var annualPay = 0;
    var annualTaxOwed = 0;

    if (salaryFrequency === "Annually"){
      annualPay = salary;
    } else if (salaryFrequency === "Monthly"){
      annualPay = salary * 12;
    } else if (salaryFrequency === "Fortnightly"){
      annualPay = salary * 26;
    } else if (salaryFrequency === "Weekly"){
      annualPay = salary * 52;
    }

    
    let taxable = {
      "index": 1,
      "type": "Before Tax Pay",
      "weekly": (annualPay / 52).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "fortnightly": (annualPay / 26).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "monthly": (annualPay / 12).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "annually": (annualPay / 1).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }
    payTotals.push(taxable);

    let superannuation = {
      "index": 2,
      "type": "Superannuation",
      "weekly": (annualPay / 52 * (superannuationRate / 100)).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "fortnightly": (annualPay / 26 * (superannuationRate / 100)).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "monthly": (annualPay / 12 * (superannuationRate / 100)).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "annually": (annualPay / 1 * (superannuationRate / 100)).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }
    payTotals.push(superannuation);

    if (annualPay <= 18200 ){
      annualTaxOwed = 0;
    }

    if (annualPay >= 18201 && annualPay <= 45000){
      annualTaxOwed = (annualPay - 18200) * 0.19;
    }

    if (annualPay >= 45001 && annualPay <= 120000){
      annualTaxOwed = (5092 + (annualPay - 45000) * 0.325);
    }

    if (annualPay >= 120001 && annualPay <= 180000){
      annualTaxOwed = (29467 + (annualPay - 120000) * 0.37);
    }

    if (annualPay >= 180001){
      annualTaxOwed = (51667 + (annualPay - 180000) * 0.45);
    }

    // Calculate income tax
    let weeklyIncomeTax = (annualTaxOwed / 52);
    let fortnightlyIncomeTax = (annualTaxOwed / 26);
    let monthlyIncomeTax = (annualTaxOwed / 12);
    let annuallyIncomeTax = (annualTaxOwed / 1);

    let taxIncome = {
      "index": 4,
      "type": "Income Tax",
      "weekly": weeklyIncomeTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "fortnightly": fortnightlyIncomeTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "monthly": monthlyIncomeTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "annually": annuallyIncomeTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) 
    }
    payTotals.push(taxIncome);

    // Calculate medicare levy
    let weeklyMedicareLevyTax = ((annualPay / 52) * 0.02);
    let fortnightlyMedicareLevyTax = ((annualPay / 26) * 0.02);
    let monthlyMedicareLevyTax = ((annualPay / 12) * 0.02);
    let annuallyMedicareLevyTax  = ((annualPay / 1) * 0.02);

    let taxMedicareLevy = {
      "index": "5",
      "type": "Medicare Levy",
      "weekly": weeklyMedicareLevyTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "fortnightly": fortnightlyMedicareLevyTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "monthly": monthlyMedicareLevyTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "annually": annuallyMedicareLevyTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }
    payTotals.push(taxMedicareLevy);

    let annualOffset = 0;

    // Calculate offsets
    if (annualPay <= 37000 ){
      annualOffset = 255;
    }

    if (annualPay >= 37001 && annualPay <= 48000){
      annualOffset = 255 + (annualPay - 37000) * 0.075;

      if (annualOffset > 1080){
        annualOffset = 1080;
      }
    }

    if (annualPay >= 48001 && annualPay <= 90000){
      annualOffset = 1080;
    }

    if (annualPay >= 90001 && annualPay <= 126000){
      annualOffset = (1080 - (annualPay - 90000) * 0.03);
    }

    annualOffset = annualOffset - (annualOffset * 2);

    let taxOffset = {
      "index": 7,
      "type": "Tax Offsets",
      "weekly": "-",
      "fortnightly": "-",
      "monthly": "-",
      "annually": annualOffset.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }
    payTotals.push(taxOffset);

    // Calculate total taxes
    let weeklyTotalTax = weeklyIncomeTax + weeklyMedicareLevyTax;
    let fortnightlyTotalTax = fortnightlyIncomeTax + fortnightlyMedicareLevyTax;
    let monthlyTotalTax = monthlyIncomeTax + monthlyMedicareLevyTax;
    let annuallyTotalTax = annuallyIncomeTax + annuallyMedicareLevyTax + annualOffset;

    let taxTotal = {
      "index": 3,
      "type": "Total Tax",
      "weekly": weeklyTotalTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "fortnightly": fortnightlyTotalTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "monthly": monthlyTotalTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "annually": annuallyTotalTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }
    payTotals.push(taxTotal);

    // Calculate after tax amounts
    let weeklyAfterTax = ((annualPay / 52) - (annuallyTotalTax / 52));
    let fortnightlyAfterTax = ((annualPay / 26) - (annuallyTotalTax / 26));
    let monthlyAfterTax = ((annualPay / 12) - (annuallyTotalTax / 12));
    let annuallyAfterTax = ((annualPay / 1) - (annuallyTotalTax / 1));

    let payAfterTax = {
      "index": 0,
      "type": "After Tax Pay",
      "weekly": weeklyAfterTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "fortnightly": fortnightlyAfterTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "monthly": monthlyAfterTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }),
      "annually": annuallyAfterTax.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }
    payTotals.push(payAfterTax);

    return (
      <>
      <div className="NewAsset">
        <h3>Renumeration</h3>

      <div class="row">
        <div class="col">
          <div class="text-center">

            Salary:&nbsp;
            $<NumberFormat
              value={salary}
              className="foo"
              displayType={'input'}
              thousandSeparator={true}
              //prefix={'$'}
              onValueChange={ (values) => {
                const { value } = values;
                setSalary(value);
                }
              }
            />
            
            <select value={salaryFrequency} onChange={(val) => setSalaryFrequency(val.target.value)} className="btn btn-sm btn-outline-secondary dropdown-toggle">
              <option value="Weekly">Weekly</option>
              <option value="Fortnightly" >Fortnightly</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Annually">Annually</option>
            </select>

            <br></br>

            Superannuation:&nbsp;<input type='number' step="0.05" value={superannuationRate} onChange={(e) => setSuperAnnuationRate(e.target.value)}/>%

          </div>
        </div>
        <div class="col">
          <div class="text-center">
            Working Hours:
            <br></br>
            Days per week:&nbsp;&nbsp;&nbsp;&nbsp;<input value={workDaysPerWeek} onChange={(e) => setWorkDaysPerWeek(e.target.value.replace(/\D/,''))}/>
            <br></br>
            Hours per Week:&nbsp;<input type='number' step='0.25' value={workHoursPerWeek} onChange={(e) => setWorkHoursPerWeek(e.target.value)}/>
          </div>

        </div>
      </div>

      <hr></hr>
        Days Per Week: {workDaysPerWeek}
        <br></br>
        Hours Per Day: {workHoursPerWeek / workDaysPerWeek}
        <br></br>
        Hours Per Week: {workHoursPerWeek}
        <hr></hr>
        <h3>Totals</h3>
        
        <div class="w-75">
          <App columnData={TableColumns.payCalcColumns} rowData={payTotals} defaultColDef={TableColumns.defaultColDef}/>
        </div>
        </div>
      </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="PayCalculator">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Pay & Tax Calculator</h2>
        <ListGroup>{!isLoading && renderPayCalculator()}</ListGroup>
      </div>
    );
  }

  return (
    <div className="PayCalculator">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}

