import React, { useRef, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

const App = (props) => {

 const gridRef = useRef(); // Optional - for accessing Grid's API

 const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

 // DefaultColDef sets props common to all Columns
 const defaultColDef = {
   flex: 1,
   headerStyle: {textAlign: 'center'},
   cellStyle: {textAlign: 'center'}
 };

 // Example of consuming Grid Event
 const cellClickedListener = useCallback( event => {
   console.log('cellClicked', event);
 }, []);

 return (
   <div>

     {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
     <div className="ag-theme-alpine" style={gridStyle}>

       <AgGridReact
           ref={gridRef} // Ref for accessing Grid's API
           domLayout={'autoHeight'}

           rowData={props.rowData} // Row Data for Rows
           columnDefs={props.columnData}
           defaultColDef={defaultColDef} // Default Column Properties

           animateRows={true} // Optional - set to 'true' to have rows animate when sorted
           rowSelection='multiple' // Options - allows click selection of rows

           onCellClicked={cellClickedListener} // Optional - registering for Grid Event
           />
     </div>
   </div>
 );
};

export default App;