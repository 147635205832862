import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";
import { onError } from "../../../lib/errorLib";
import "./NewLiability.css";
import { API } from "aws-amplify";

export default function NewLiability() {

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0,10);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const itemType = "liability";
  const [itemSubType, setItemSubType] = useState("");
  const [active, setActive] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemNote, setItemNote] = useState("");
  const [itemStartDate, setItemStartDate] = useState("");
  const [itemStartAmount, setItemStartAmount] = useState("");
  const [itemMinRepayment, setItemMinRepayment] = useState("");
  const [itemMinRepaymentFrequency, setItemMinRepaymentFrequency] = useState("");
  const [itemCurrentDate, setItemCurrentDate] = useState(date);
  const [itemCurrentAmount, setItemCurrentAmount] = useState("");
  const [itemCurrentRepayment, setItemCurrentRepayment] = useState("");
  const [itemCurrentRepaymentFrequency, setItemCurrentRepaymentFrequency] = useState("");
  const [itemNextRepayment, setItemNextRepayment] = useState("");
  const [itemInterestRate, setItemInterestRate] = useState("");
  const [itemInterestFrequency, setItemInterestFrequency] = useState("");
  const [itemFee, setItemFee] = useState("");
  const [itemFeeFrequency, setItemFeeFrequency] = useState("");

  const handleOnChange = () => {
    setActive(!active);
  };

  function validateForm() {
    return itemNote.length > 0;
  }

  async function handleSubmit(event) {

    event.preventDefault();
  
    setIsLoading(true);
  
    try {
 
      await createBudgetItem({ active, itemType, itemSubType, itemName, itemNote, itemStartDate, itemStartAmount, itemMinRepayment, itemMinRepaymentFrequency, itemCurrentDate, itemCurrentAmount, itemCurrentRepayment, itemCurrentRepaymentFrequency, itemNextRepayment, itemInterestRate, itemInterestFrequency, itemFee, itemFeeFrequency});
      navigate("/portfolio/liabilities", { replace: true });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createBudgetItem(budgetItem) {
    return API.post("portfolio", "/liabilities/add", {
      body: budgetItem
    });
  }

  return (
    <div className="NewAsset">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Create new liability</h2>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="itemStartDate" size="lg">
          <Form.Label>Start date of Liability</Form.Label>
          <Form.Control 
            type="date" 
            name='itemStartDate'
            defaultValue={itemStartDate}
            onChange={(e) => setItemStartDate(e.target.value)}
            />
      </Form.Group>
      <Form.Group controlId="itemCurrentDate" size="lg">
          <Form.Label>Current Date of Liability</Form.Label>
          <Form.Control 
            type="date" 
            name='itemCurrentDate'
            defaultValue={itemCurrentDate}
            onChange={(e) => setItemCurrentDate(e.target.value)}
            />
      </Form.Group>
      <Form.Group controlId="subtypeOfItem" size="lg">
          <Form.Label>Type of Liability</Form.Label>
          <Form.Control
            autoFocus
            type="itemSubType"
            value={itemSubType}
            placeholder="Type of Liability"
            onChange={(e) => setItemSubType(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemName" size="lg">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="itemName"
            value={itemName}
            placeholder="Name"
            onChange={(e) => setItemName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemNote" size="lg">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="itemNote"
            value={itemNote}
            placeholder="Note"
            onChange={(e) => setItemNote(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemStartAmount" size="lg">
          <Form.Label>Start Amount</Form.Label>
          <Form.Control
            type="itemStartAmount"
            value={itemStartAmount}
            placeholder="Starting Amount"
            onChange={(e) => setItemStartAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemMinRepayment" size="lg">
          <Form.Label>Min Repayment</Form.Label>
          <Form.Control
            type="itemMinRepayment"
            value={itemMinRepayment}
            placeholder="Min Repayment"
            onChange={(e) => setItemMinRepayment(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemMinRepaymentFrequency" size="lg">
          <Form.Label>Min Repayment Frequency</Form.Label>
          <Form.Control
            type="itemMinRepaymentFrequency"
            value={itemMinRepaymentFrequency}
            placeholder="Min Repayment Frequency"
            onChange={(e) => setItemMinRepaymentFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemCurrentAmount" size="lg">
          <Form.Label>Current Amount</Form.Label>
          <Form.Control
            type="itemCurrentAmount"
            value={itemCurrentAmount}
            placeholder="Current Repayment"
            onChange={(e) => setItemCurrentAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemCurrentRepayment" size="lg">
          <Form.Label>Current Repayment</Form.Label>
          <Form.Control
            type="itemCurrentRepayment"
            value={itemCurrentRepayment}
            placeholder="Current Repayment"
            onChange={(e) => setItemCurrentRepayment(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemCurrentRepaymentFrequency" size="lg">
          <Form.Label>Current Repayment Frequency</Form.Label>
          <Form.Control
            type="itemCurrentRepaymentFrequency"
            value={itemCurrentRepaymentFrequency}
            placeholder="Current Repayment"
            onChange={(e) => setItemCurrentRepaymentFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemNextRepayment" size="lg">
          <Form.Label>Next Repayment Due</Form.Label>
          <Form.Control 
            type="date" 
            name='itemNextRepayment'
            defaultValue={itemNextRepayment}
            onChange={(e) => setItemNextRepayment(e.target.value)}
            />
        </Form.Group>
        <Form.Group controlId="itemInterestRate" size="lg">
          <Form.Label>Interest Rate</Form.Label>
          <Form.Control
            type="itemInterestRate"
            value={itemInterestRate}
            placeholder="Interest Rate"
            onChange={(e) => setItemInterestRate(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemInterestFrequency" size="lg">
          <Form.Label>Interest Applied Frequency</Form.Label>
          <Form.Control
            type="itemInterestFrequency"
            value={itemInterestFrequency}
            placeholder="Interest Applied Frequency"
            onChange={(e) => setItemInterestFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemFee" size="lg">
          <Form.Label>Fees</Form.Label>
          <Form.Control
            type="itemFee"
            value={itemFee}
            placeholder="Fees"
            onChange={(e) => setItemFee(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="itemFeeFrequency" size="lg">
          <Form.Label>Fee Applied Frequency</Form.Label>
          <Form.Control
            type="itemFeeFrequency"
            value={itemFeeFrequency}
            placeholder="Fee Applied Frequency"
            onChange={(e) => setItemFeeFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="active" size="lg">
          <Form.Label>Liability Active</Form.Label>
          <Form.Check
            type="checkbox"
            id="active"
            label="Active"
            checked={active}
            onChange={handleOnChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}