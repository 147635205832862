    import * as TableConstants from "./constants"

    export const tableHeaders = "#8c96d7";

    // Totals Table columns
    export const totalColumns = [{
        dataField: "index",
        text: "index",
        sort: true,
        hidden: true,
      }, {
        dataField: "type",
        text: "Rate",
        formatter: TableConstants.payRatesFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "rate",
        text: "Hour",
        formatter: TableConstants.payRatesFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "rateDaily",
        text: "Day",
        formatter: TableConstants.payRatesFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "rateCustom",
        text: "Custom",
        formatter: TableConstants.payRatesFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }];

    // Totals Table columns
    export const payRateColumns = [
      {field: 'index', hide: true, filter: false},
      {field: 'type', headerName: "Type", filter: false},
      {field: 'rate', headerName: "Hour", filter: false},
      {field: 'rateDaily', headerName: "Day", filter: false},
      {field: 'rateCustom', headerName: "Custom", filter: false}
    ];