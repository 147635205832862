    // Totals Table columns
    export const payCalcColumns = [
      {field: 'index', hide: true, filter: false, sort: "asc"},
      {field: 'type', headerName: "Type", filter: false},
      {field: 'weekly', headerName: "Weekly", filter: false},
      {field: 'fortnightly', headerName: "Fortnightly", filter: false},
      {field: 'monthly', headerName: "Monthly", filter: false},
      {field: 'annually', headerName: "Yearly", filter: false}
    ];

    export const defaultColDef = [
      {flex: 1}
    ]