import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import Routes from "./Routes";
import "./App.css";

function App() {
  const nav = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  // Grab top level of pathname for highlighting purposes
  var indOf = window.location.pathname.indexOf("/",1);
  var myStr = window.location.pathname.substr(0,indOf+1 );

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    nav("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App container py-3">
        <Navbar bg="dark" variant="dark" expand="md">
          <LinkContainer to="/">
            <Navbar.Brand>
            Making Budgets
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-left">
            <Nav activeKey={myStr}>
              {isAuthenticated ? (
                <>
                  <LinkContainer to="/budget/">
                    <Nav.Link>Budget</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/portfolio/">
                    <Nav.Link>Portfolio</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/tools/">
                    <Nav.Link>Tools</Nav.Link>
                  </LinkContainer>
                </>
              ) : (
                <>

                </>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                  <LinkContainer to="/donate">
                    <Nav.Link>Donate</Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated }}
          >
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;
