import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";
import { onError } from "../../../lib/errorLib";
import "./NewExpense.css";
import { API } from "aws-amplify";
import { useAppContext } from "../../../lib/contextLib";
import ListGroup from "react-bootstrap/ListGroup";

export default function NewExpense() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAppContext();
  const [itemType] = useState("expense");
  const [itemSubType, setItemSubType] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [frequency, setFrequency] = useState("FORTNIGHT");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return description.length > 0;
  }

  async function handleSubmit(event) {

    if (frequency === ""){
      setFrequency("FORTNIGHTLY")
    }


    console.log("Variables:")
    console.log("Type: " + itemType);
    console.log("SubType: " + itemSubType);
    console.log("Name: " + description);
    console.log("Amount: " + amount);
    console.log("Frequncy: " + frequency);
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
 
      await createBudgetItem({ itemType, itemSubType, description, amount, frequency});
      navigate("/budget/expenses", { replace: true });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createBudgetItem(budgetItem) {
    return API.post("notes", "/budget/add-item", {
      body: budgetItem
    });
  }

  function renderBudgetItemsList() {
  return (
    <div className="NewExpense">
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="subType" size="lg">
          <Form.Label>Type</Form.Label>
          <Form.Control
            autoFocus
            type="itemSubType"
            value={itemSubType}
            placeholder="Type of Expense"
            onChange={(e) => setItemSubType(e.target.value)}
          />
        </Form.Group>
      <Form.Group controlId="description" size="lg">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="description"
            value={description}
            placeholder="Description of Expense"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="amount" size="lg">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            autoFocus
            type="amount"
            value={amount}
            placeholder="Amount of Expense"
            onChange={(e) => setAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="frequency" size="lg">
          <Form.Label>Frequency: </Form.Label>
          <select value={frequency} onChange={(val) => setFrequency(val.target.value)} className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <option value="WEEK">Weekly</option>
            <option value="FORTNIGHT" >Fortnightly</option>
            <option value="MONTH">Monthly</option>
            <option value="QUARTER">Quarterly</option>
            <option value="YEAR">Yearly</option>
          </select>
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}

function renderLander() {
  return (
    <div className="lander">
      <h1>Making Budgets</h1>
      <p className="text-muted">A simple tool for making budgets</p>
    </div>
  );
}

function renderBudgetItems() {
  return (
    <div className="budgetItems">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Create new expense</h2>
      <ListGroup>{!isLoading && renderBudgetItemsList()}</ListGroup>
    </div>
  );
}

return (
  <div className="Income">
    {isAuthenticated ? renderBudgetItems() : renderLander()}
  </div>
);
}