import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";

import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import "./Overview.css";
import { API } from "aws-amplify";
import { isMobile } from "react-device-detect";
//import PercentSubTypePieChart from "./Charts/PercentSubTypePieChart";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

import { Chart } from "react-google-charts";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";

export default function PortfolioOverview() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const assets = await loadAssets();
        setAssets(assets);
      } catch (e) {
        onError(e);
      }

      try {
        const liabilities = await loadLiabilities();
        setLiabilities(liabilities);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    function loadAssets(assets) {
      return API.get("portfolio", `/list-items/asset`);
    }

    function loadLiabilities(liabilities) {
      return API.get("portfolio", `/list-items/liability`);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderPortfolioList(assets) {
    if (isMobile) {
      require("./custom.css");
    }

    let objIndex;
    let assetSubtypes = [];
    let assetTotals = 0;
    let liabilitySubtypes = [];
    let liabilityTotals = 0;
    let assetDates = [];
    let liabilityDates = [];

    let cashTypes = [];
    let cryptoTypes = [];
    let sharesTypes = [];

    let totalCash = 0;
    let totalCrypto = 0;
    let totalStock = 0;

    let percentOfCashAsset = {
      labels: [],
      data: [],
    };

    let percentOfCryptoAsset = {
      labels: [],
      data: [],
    };

    let percentOfStockAsset = {
      labels: [],
      data: [],
    };

    for (let assetItem of assets) {
      if (assetItem.active === "false") {
        break;
      }

      if (assetItem.itemSubType === "Cash") {
        if (
          cashTypes.some(
            (subTypeItem) => subTypeItem.subtype === assetItem.assetName
          )
        ) {
          objIndex = cashTypes.findIndex(
            (subTypeItem) => subTypeItem.subtype === assetItem.assetName
          );
          cashTypes[objIndex].totalAmount =
            parseInt(cashTypes[objIndex].itemTotalPrice) +
            parseInt(assetItem.itemTotalPrice);
        } else {
          let subTypeItem = {
            subtype: assetItem.itemSubType,
            assetName: assetItem.assetName,
            assetNameCode:
              assetItem.assetName + " (" + assetItem.itemCode + ")",
            totalAmount: parseInt(assetItem.itemTotalPrice),
          };
          cashTypes.push(subTypeItem);
        }

        totalCash = parseInt(totalCash + assetItem.itemTotalPrice);
      }

      if (assetItem.itemSubType === "Crypto") {
        if (
          cryptoTypes.some(
            (subTypeItem) => subTypeItem.subtype === assetItem.assetName
          )
        ) {
          objIndex = cryptoTypes.findIndex(
            (subTypeItem) => subTypeItem.subtype === assetItem.assetName
          );
          cryptoTypes[objIndex].totalAmount =
            parseInt(cryptoTypes[objIndex].itemTotalPrice) +
            parseInt(assetItem.itemTotalPrice);
        } else {
          let subTypeItem = {
            subtype: assetItem.itemSubType,
            assetName: assetItem.assetName,
            assetNameCode:
              assetItem.assetName + " (" + assetItem.itemCode + ")",
            totalAmount: parseInt(assetItem.itemTotalPrice),
          };
          cryptoTypes.push(subTypeItem);
        }

        totalCrypto = parseInt(totalCrypto + assetItem.itemTotalPrice);
      }

      if (assetItem.itemSubType === "Stock") {
        if (
          sharesTypes.some(
            (subTypeItem) => subTypeItem.subtype === assetItem.assetName
          )
        ) {
          objIndex = sharesTypes.findIndex(
            (subTypeItem) => subTypeItem.subtype === assetItem.assetName
          );
          sharesTypes[objIndex].totalAmount =
            parseInt(sharesTypes[objIndex].itemTotalPrice) +
            parseInt(assetItem.itemTotalPrice);
        } else {
          let subTypeItem = {
            subtype: assetItem.itemSubType,
            assetName: assetItem.assetName,
            assetNameCode:
              assetItem.assetName + " (" + assetItem.itemCode + ")",
            totalAmount: parseInt(assetItem.itemTotalPrice),
          };
          sharesTypes.push(subTypeItem);
        }

        totalStock = parseInt(totalStock + assetItem.itemTotalPrice);
      }

      assetDates.push(new Date(assetItem.itemDate));

      assetTotals = parseInt(assetTotals) + parseInt(assetItem.itemTotalPrice);

      if (
        assetSubtypes.some(
          (subTypeItem) => subTypeItem.subtype === assetItem.itemSubType
        )
      ) {
        objIndex = assetSubtypes.findIndex(
          (subTypeItem) => subTypeItem.subtype === assetItem.itemSubType
        );
        assetSubtypes[objIndex].totalAmount =
          assetSubtypes[objIndex].totalAmount + assetItem.itemTotalPrice;
      } else {
        let subTypeItem = {
          subtype: assetItem.itemSubType,
          totalAmount: assetItem.itemTotalPrice,
        };
        assetSubtypes.push(subTypeItem);
      }
    }

    cashTypes.forEach((cashType) => {
      cashType["totalAmountString"] = cashType.totalAmount.toLocaleString();
      cashType["percentage"] = parseFloat(
        (parseInt(cashType.totalAmount) / parseInt(totalCash)) * 100
      ).toFixed(1);
      percentOfCashAsset.labels.push(cashType.subtype);
      percentOfCashAsset.data.push(cashType.totalAmount);
    });

    cryptoTypes.forEach((cryptoTypes) => {
      cryptoTypes["totalAmountString"] =
        cryptoTypes.totalAmount.toLocaleString();
      cryptoTypes["percentage"] = parseFloat(
        (parseInt(cryptoTypes.totalAmount) / parseInt(totalCrypto)) * 100
      ).toFixed(1);
      percentOfCryptoAsset.labels.push(cryptoTypes.subtype);
      percentOfCryptoAsset.data.push(cryptoTypes.totalAmount);
    });

    sharesTypes.forEach((sharesType) => {
      sharesType["totalAmountString"] = sharesType.totalAmount.toLocaleString();
      sharesType["percentage"] = parseFloat(
        (parseInt(sharesType.totalAmount) / parseInt(totalStock)) * 100
      ).toFixed(1);
      percentOfStockAsset.labels.push(sharesType.subtype);
      percentOfStockAsset.data.push(sharesType.totalAmount);
    });

    assetSubtypes.forEach((assetSubType) => {
      assetSubType["totalAmountString"] =
        assetSubType.totalAmount.toLocaleString();
      assetSubType["percentage"] = parseFloat(
        (assetSubType.totalAmount / assetTotals) * 100
      ).toFixed(1);
      assetSubType["percentageText"] = assetSubType.percentage + "%";
    });

    // Start liabliities
    for (let liabiltiesItem of liabilities) {
      if (liabiltiesItem.active === "false") {
        break;
      }

      liabilityDates.push(new Date(liabiltiesItem.itemCurrentDate));

      liabilityTotals =
        parseInt(liabilityTotals) + parseInt(liabiltiesItem.itemCurrentAmount);

      if (
        liabilitySubtypes.some(
          (subTypeItem) => subTypeItem.subtype === liabiltiesItem.itemSubType
        )
      ) {
        objIndex = liabilitySubtypes.findIndex(
          (subTypeItem) => subTypeItem.subtype === liabiltiesItem.itemSubType
        );
        liabilitySubtypes[objIndex].totalAmount =
          liabilitySubtypes[objIndex].totalAmount +
          liabiltiesItem.itemCurrentAmount;
      } else {
        let subTypeItem = {
          subtype: liabiltiesItem.itemSubType,
          totalAmount: liabiltiesItem.itemCurrentAmount,
        };
        liabilitySubtypes.push(subTypeItem);
      }
    }

    console.log("Total liability: " + liabilityTotals);

    liabilitySubtypes.forEach((liabilitySubType) => {
      liabilitySubType["totalAmountString"] = parseFloat(
        liabilitySubType.totalAmount
      ).toLocaleString();
      liabilitySubType["percentage"] = (
        (liabilitySubType.totalAmount / liabilityTotals) *
        100
      ).toFixed(1);
      liabilitySubType["percentageText"] = liabilitySubType.percentage + "%";
    });

    // Percentage of assets
    let percentOfLiabilitySubtype = {
      labels: [],
      data: [],
    };

    liabilitySubtypes.forEach((liabilitySubtype) => {
      percentOfLiabilitySubtype.labels.push(liabilitySubtype.subtype);
      //percentOfLiabilitySubtype.data.push(liabilitySubtype.totalAmount);
      percentOfLiabilitySubtype.data.push(liabilitySubtype.percentage);
    });

    var testTotal = [];
    testTotal.push(["Type", "Amount"]);
    testTotal.push(["Assets", assetTotals]);
    testTotal.push(["Liabilities", liabilityTotals]);

    console.log(assetSubtypes);

    var assetSource = [];
    assetSource.push(["Type", "Amount"]);
    assetSubtypes.forEach((assetSubtype) => {
      assetSource.push([assetSubtype.subtype, assetSubtype.totalAmount]);
    });

    var liabilitySource = [];
    liabilitySource.push(["Type", "Amount"]);
    liabilitySubtypes.forEach((liabilitySubtype) => {
      liabilitySource.push([
        liabilitySubtype.subtype,
        liabilitySubtype.totalAmount,
      ]);
    });

    console.log("AssetSource");
    console.log(assetSource);

    let latestAssetDate = "1/2/2000";
    let latestLiabilityDate = "1/2/2000";

    if (assetDates.length > 0) {
      latestAssetDate = new Date(Math.max.apply(null, assetDates));
    }

    if (liabilityDates.length > 0) {
      latestLiabilityDate = new Date(Math.max.apply(null, liabilityDates));
    }
    //var minimumDate=new Date(Math.min.apply(null, dates));

    console.log("Latest asset date: " + latestAssetDate);
    console.log("Latest liability date: " + latestLiabilityDate);

    const optionsOverall = {
      title: "",
      legend: "bottom",
      pieSliceText: "none",
      pieHole: 0.4,
    };

    return (
      <>
        <div class="row">
          <div class="col">
            <div class="text-center date-left">
              Last Asset Update:
              <br></br>
              {new Date(latestAssetDate).toISOString().slice(0, 10)}
            </div>
            <hr></hr>
          </div>
          <div class="col">
            <div class="text-center">
              Last Liability Update:
              <br></br>
              {new Date(latestLiabilityDate).toISOString().slice(0, 10)}
            </div>
            <hr></hr>
          </div>
        </div>

        <h3 class="text-center">Overview</h3>

        <div class="row">
          <div class="col">
            <div class="text-right">
              <div class="text-right">Assets:&nbsp;</div>
              <div>Liabilities:&nbsp;</div>
              <div>
                <aa>&nbsp;&nbsp;&nbsp;Position:&nbsp;</aa>
              </div>
              <br></br>
            </div>
          </div>
          <div class="col">
            <div class="text-left">
              <div>
                {assetTotals.toLocaleString("en-AU", {
                  style: "currency",
                  currency: "AUD",
                })}
              </div>
              <div>
                {liabilityTotals.toLocaleString("en-AU", {
                  style: "currency",
                  currency: "AUD",
                })}
              </div>
              <div>
                <aa>
                  {(assetTotals - liabilityTotals).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })}
                </aa>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Chart
            chartType="PieChart"
            data={testTotal}
            options={optionsOverall}
            width={"100%"}
            height={"400px"}
          />
        </div>
        <div>
          <hr />
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <LinkContainer to="/portfolio/assets/new">
                  <ListGroup.Item
                    action
                    className="py-3 text-nowrap text-truncate text-center"
                  >
                    <BsPencilSquare size={17} />
                    <span className="ml-2 font-weight-bold"> Add Asset</span>
                  </ListGroup.Item>
                </LinkContainer>
              </div>
              <div class="col-sm">
                <LinkContainer to="/portfolio/liabilities/new">
                  <ListGroup.Item
                    action
                    className="py-3 text-nowrap text-truncate text-center"
                  >
                    <BsPencilSquare size={17} />
                    <span className="ml-2 font-weight-bold">
                      {" "}
                      Add Liability
                    </span>
                  </ListGroup.Item>
                </LinkContainer>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <h3 class="text-center">
          <header-underline>Breakdown</header-underline>
        </h3>
        <div class="row">
          <div class="col-sm">
            <div>
              <h3 class="text-center">Assets</h3>
            </div>
            <div>
              <Chart
                chartType="PieChart"
                data={assetSource}
                options={optionsOverall}
                width={"100%"}
                height={"400px"}
              />
            </div>
            <div>
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: 600 }}
              ></div>
              TABLE data=assetSubtypes columns=TableColumns.assetSubTypeColumns
            </div>
          </div>
          <div class="col-sm">
            <div>
              <h3 class="text-center">Liabilities</h3>
            </div>
            <div>
              <Chart
                chartType="PieChart"
                data={liabilitySource}
                options={optionsOverall}
                width={"100%"}
                height={"400px"}
              />
            </div>
            <div>
              TABLE data = liabilitySubtypes columns =
              TableColumns.liabilitySubTypeColumns
            </div>
          </div>
        </div>

        <div>
          <hr></hr>
        </div>

        <Button
          onClick={() => setOpen(!open)}
          aria-controls="collapse-budget-items"
          aria-expanded={open}
        >
          Show/Hide Asset Breakdown
        </Button>

        <Collapse in={open}>
          <div id="collapse-budget-items">
            <div>
              <hr></hr>
            </div>
            <h3 class="text-center">
              <header-underline>Asset Breakdown</header-underline>
            </h3>
            <div class="row">
              <div class="col-md">
                <div>
                  <h3 class="text-center">Cash</h3>
                </div>
                <div>PIE Chart, percents=percentOfCashAsset</div>
                <div>
                  TABLE data = cashTypes columns =
                  TableColumns.assetCashBreakdownColumns
                </div>
              </div>
              <div class="col-md">
                <div>
                  <h3 class="text-center">Crypto</h3>
                </div>
                <div>PIE chart, percents=percentOfCryptoAsset</div>
                <div>
                  TABLE data = cryptoTypes columns =
                  TableColumns.assetCryptoBreakdownColumns
                </div>
              </div>
              <div class="col-md">
                <div>
                  <h3 class="text-center">Stock</h3>
                </div>
                <div>PIE Chart, percents=percentOfStockAsset</div>
                <div>
                  TABLE data = sharesTypes columns =
                  TableColumns.assetStockBreakdownColumns
                </div>
              </div>
            </div>
          </div>
        </Collapse>

        <div>
          <hr></hr>
        </div>
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Portfolio Overview</h2>
        <ListGroup>{!isLoading && renderPortfolioList(assets)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Overview">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}
