import React from "react";
import {Route, Routes} from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import NotFound from "./containers/NotFound";

// Top Menu Section
import SubMenu from "./containers/SubMenu";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Donate from "./containers/Donate";

// Notes Section
import Notes from "./containers/Notes/Notes";
import NewNote from "./containers/Notes/NewNote";

// Budget Section
import Overview from "./containers/Budget/Overview/Overview";
import Items from "./containers/Budget/Items/Items";
import Income from "./containers/Budget/Income/Income";
import NewIncome from "./containers/Budget/Income/NewIncome";
import Expense from "./containers/Budget/Expenses/Expenses";
import NewExpense from "./containers/Budget/Expenses/NewExpense";
import Savings from "./containers/Budget/Savings/Savings";
import NewSaving from "./containers/Budget/Savings/NewSaving";

// Portfolio Section
import Portfolio from "./containers/Portfolio/Overview/Overview";
import Assets from "./containers/Portfolio/Assets/Assets";
import NewAsset from "./containers/Portfolio/Assets/NewAsset"
import Asset from "./containers/Portfolio/Assets/Asset/Asset";

import Liabilities from "./containers/Portfolio/Liabilities/Liabilities";
import NewLiability from "./containers/Portfolio/Liabilities/NewLiability"
import Liability from "./containers/Portfolio/Liabilities/Liability/Liability";

// Tools Sections
import PayCalculator from "./containers/Tools/PayCalculator/PayCalculator";
import PayRates from "./containers/Tools/PayRates/PayRates";

export default function Links() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>

            <Route path="/login"
                   element={
                       <UnauthenticatedRoute>
                           <Login/>
                       </UnauthenticatedRoute>
                   }
            />
            <Route path="/signup"
                   element={
                       <UnauthenticatedRoute>
                           <Signup/>
                       </UnauthenticatedRoute>
                   }
            />
            <Route path="/donate"
                   element={
                       <AuthenticatedRoute>
                           <Donate/>
                       </AuthenticatedRoute>
                   }
            />
            <Route path="/notes/new"
                   element={
                       <AuthenticatedRoute>
                           <NewNote/>
                       </AuthenticatedRoute>
                   }
            />
            <Route path="/notes/:id"
                   element={
                       <AuthenticatedRoute>
                           <Notes/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Overview/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/overview"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Overview/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/items/:id"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Items/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/income"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Income/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/income/new"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <NewIncome/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/expenses"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Expense/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/expenses/new"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <NewExpense/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/savings"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Savings/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/budget/savings/new"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <NewSaving/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Portfolio/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/overview"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Portfolio/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/assets"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Assets/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/assets/new"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <NewAsset/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/assets/:id"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Asset/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/liabilities"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Liabilities/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/liabilities/new"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <NewLiability/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/portfolio/liabilities/:id"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <Liability/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/tools"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <PayCalculator/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/tools/paycalculator"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <PayCalculator/>
                       </AuthenticatedRoute>
                   }
            />

            <Route path="/tools/payrates"
                   element={
                       <AuthenticatedRoute>
                           <SubMenu/>
                           <PayRates/>
                       </AuthenticatedRoute>
                   }
            />


            {/* Finally, catch all unmatched routes */}
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}
