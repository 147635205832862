import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";

class ItemListGroupMap extends React.Component {

render() {

 return (
    <Container>
    <Row>
      {this.props.items.map(({ itemId, itemSubType, description, amount, frequency }) => (
        <Col md="4" xs="6">
          <LinkContainer key={itemId} to={`/budget/items/${itemId}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {itemSubType.trim().split("\n")[0]}
              </span>
              <br></br>
              <span className="text-muted">
                {description}
              </span>
              <br></br>
              <span className="text-muted">
                ${amount}
              </span>
              <br></br>
            </ListGroup.Item>
          </LinkContainer>
        </Col>
      ))}
    </Row>
  </Container>
 );
}
}

export default ItemListGroupMap;