import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import "./Assets.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

export default function Assets() {
  const [itemType] = useState("asset");
  const [assets, setAssets] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadAssets() {
      return API.get("portfolio", `/list-items/asset`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const assets = await loadAssets();
        setAssets(assets);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, itemType]);

  function renderAssetsList(assets) {
    // Do Stuff

    console.log(assets);

    assets.forEach((asset) => {
      asset["itemTotalPrice"] = asset.itemTotalPrice.toLocaleString(undefined, {
        maximumFractionDigits: 5,
      });
    });

    assets.forEach((asset) => {
      asset["itemSellPricePretty"] = parseFloat(
        asset.itemSellPrice
      ).toLocaleString(undefined, { maximumFractionDigits: 5 });
    });

    //    function priceFormatter(cell, row) {
    //      if (row.onSale) {
    //        return (
    //          <span>
    //            <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong>
    //          </span>
    //        );
    //      }
    //
    //      return (
    //
    //        <span>${ cell } </span>
    //      );
    //    }

    //    const sortType = [{
    //      dataField: "itemType",
    //      order: "asc"
    //    }];

    //    const cryptoColumns = [{
    //      dataField: "itemId",
    //      text: "itemId",
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //	  hidden: true ,
    //    }, {
    //      dataField: "itemDate",
    //      text: "Date",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      }
    //    }, {
    //      dataField: "itemType",
    //      text: "Type",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //    }, {
    //      dataField: "itemName",
    //      text: "Name",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //    }, {
    //      dataField: "itemCode",
    //      text: "Code",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //    }, {
    //      dataField: "itemQuantity",
    //      text: "Quantity",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //    }, {
    //      dataField: "itemSellPricePretty",
    //      text: "Sell Price",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //      formatter: priceFormatter,
    //      sortFunc: (a, b, order) => {
    //        const numA = parseFloat(a.replace(/,/g,''), 10)
    //        const numB = parseFloat(b.replace(/,/g,''), 10)
    //        if (order === "asc") {
    //            return numB - numA;
    //        }
    //        return numA - numB;
    //      }
    //    }, {
    //      dataField: "itemTotalPrice",
    //      text: "Total",
    //      type: Number,
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //      formatter: priceFormatter,
    //      sortFunc: (a, b, order) => {
    //        const numA = parseFloat(a.replace(/,/g,''), 10)
    //        const numB = parseFloat(b.replace(/,/g,''), 10)
    //        if (order === "asc") {
    //            return numB - numA;
    //        }
    //        return numA - numB;
    //      }
    //    }, {
    //      dataField: "itemNote",
    //      text: "Notes",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      },
    //    }, {
    //      dataField: "assetActive",
    //      text: "Active",
    //      sort: true,
    //      headerStyle: {
    //        backgroundColor: tableHeaders
    //      }
    //    }];

    // Sort assets by type
    assets.sort(function (a, b) {
      var textA = a.itemType.toUpperCase();
      var textB = b.itemType.toUpperCase();

      return textA.localeCompare(textB);
    });

    return (
      <>
        <hr></hr>
        <LinkContainer to="/portfolio/assets/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Add a new asset</span>
          </ListGroup.Item>
        </LinkContainer>
        <div class="text-center">
          <Container>
            <Row>
              {assets.map(
                ({
                  itemId,
                  itemDate,
                  itemSubType,
                  itemName,
                  itemCode,
                  itemQuantity,
                  itemSellPrice,
                  itemTotalPrice,
                }) => (
                  <Col md="4" xs="6">
                    <LinkContainer
                      key={itemId}
                      to={`/portfolio/assets/${itemId}`}
                    >
                      <ListGroup.Item action>
                        <span className="font-weight-bold">
                          {itemSubType.trim().split("\n")[0]}
                        </span>
                        <br></br>
                        <span className="text-muted">{itemDate}</span>
                        <br></br>
                        <span className="text-muted">
                          {itemName} ({itemCode})
                        </span>
                        <br></br>
                        {itemSubType !== "Cash" && (
                          <span className="text-muted">
                            {itemQuantity} @ ${itemSellPrice}
                          </span>
                        )}
                        <br></br>
                        <span className="font-weight-bold">
                          ${itemTotalPrice}
                        </span>
                      </ListGroup.Item>
                    </LinkContainer>
                  </Col>
                )
              )}
            </Row>
          </Container>
        </div>
        <div>
          <hr></hr>
        </div>
        TABLE HERE
      </>
    );
  }
  // <BootstrapTable bootstrap4 keyField="itemId" defaultSorted= { sortType } data={ assets } columns={ cryptoColumns } wrapperClasses="table-responsive" striped hover condensed />
  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderAssets() {
    return (
      <div className="assets">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Assets</h2>
        <ListGroup>{!isLoading && renderAssetsList(assets)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Assets">
      {isAuthenticated ? renderAssets() : renderLander()}
    </div>
  );
}
