import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import "./Income.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { calculateTotals } from "../../../lib/calculateTotals";

import App from "../../../components/tables/Table";
import * as TableColumns from "./Tables/columns.js";
import ItemListGroups from "../Common/ItemListGroups";

export default function Income() {
  const [itemType] = useState("income");
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    function loadBudgetItems(budgetItems) {
      return API.get("budget", `/list-items/${itemType}`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated, itemType]);



  function renderBudgetItemsList(budgetItems) {

//    const divStyle = {
//      float: 'right', // note the capital 'W' here
//      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
//    };

  let incomeTotals = [];
  let index;
  let incomeTotalsIndex;
  
  //let quarterAmount = 0;

  for (let budgetItem of budgetItems) {
    if (incomeTotals.some(budgetLineTotal => budgetLineTotal.typeTotal === budgetItem.itemType)){
      incomeTotalsIndex = incomeTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === budgetItem.itemType);
      incomeTotals[incomeTotalsIndex].amountWeek = incomeTotals[incomeTotalsIndex].amountWeek + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "WEEK"))
      incomeTotals[incomeTotalsIndex].amountFortnight = incomeTotals[incomeTotalsIndex].amountFortnight + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "FORTNIGHT"))
      incomeTotals[incomeTotalsIndex].amountMonth = incomeTotals[incomeTotalsIndex].amountMonth + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "MONTH"))
      incomeTotals[incomeTotalsIndex].amountQuarter = incomeTotals[incomeTotalsIndex].amountQuarter + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "QUARTER"))
      incomeTotals[incomeTotalsIndex].amountYear = incomeTotals[incomeTotalsIndex].amountYear + parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR"))
    } else {
      let budgetLineTotal = {
        "index": index,
        "typeTotal": budgetItem.itemType,
        "amountWeek": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "WEEK")),
        "amountFortnight": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "FORTNIGHT")),
        "amountMonth": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "MONTH")),
        "amountQuarter": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "QUARTER")),
        "amountYear": parseInt(calculateTotals(budgetItem.amount, budgetItem.frequency, "YEAR"))
      }
      incomeTotals.push(budgetLineTotal);
    }

  }

  let yearBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
  {
      frequency.includes("YEAR") && item.push({itemId, itemSubType, description, amount, frequency});
      return item;
  }, []);

  let quarterBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
  {
      frequency.includes("QUARTER") && item.push({itemId, itemSubType, description, amount, frequency});
      return item;
  }, []);

  let monthBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
  {
      frequency.includes("MONTH") && item.push({itemId, itemSubType, description, amount, frequency});
      return item;
  }, []);

  let fortnightBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
  {
      frequency.includes("FORTNIGHT") && item.push({itemId, itemSubType, description, amount, frequency});
      return item;
  }, []);

  let weekBudgetItems = budgetItems.reduce((item, {itemId, itemSubType, description, amount, frequency}) =>
  {
      frequency.includes("WEEK") && item.push({itemId, itemSubType, description, amount, frequency});
      return item;
  }, []);

//<BootstrapTable bootstrap4 keyField='id' data={ incomeTotals } columns={ totalColumns } wrapperClasses="table-responsive" striped hover condensed />

  return (
    <>
      <h3>Total - Averaged over 12 months</h3>
      <div style={{height: '100px'}}>
      <App columnData={TableColumns.incomeTotalCols} rowData={incomeTotals}/>
      </div>
      <LinkContainer to="/budget/income/new">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold">Add a new income</span>
        </ListGroup.Item>
      </LinkContainer>
        <ItemListGroups yearBudgetItems={yearBudgetItems} quarterBudgetItems={quarterBudgetItems} monthBudgetItems={monthBudgetItems} fortnightBudgetItems={fortnightBudgetItems} weekBudgetItems={weekBudgetItems}/>
      </>
  );
}

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Income</h2>
        <ListGroup>{!isLoading && renderBudgetItemsList(budgetItems)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Income">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}