import React from "react";
import { Container } from "react-bootstrap";
import ItemListGroupMap from "./ItemListGroupMap";

class ItemListGroups extends React.Component {

render() {

 return (
    <Container>
        <div className="text-center">
            <hr></hr>
            {this.props.yearBudgetItems.length > 0 &&
                <>
                    <h3>Yearly ({this.props.yearBudgetItems.length} items)</h3>
                    <ItemListGroupMap items={this.props.yearBudgetItems}/>
                    <hr></hr>
                </>
            }
            {this.props.quarterBudgetItems.length > 0 &&
                <>
                    <h3>Quarterly ({this.props.quarterBudgetItems.length} items)</h3>
                    <ItemListGroupMap items={this.props.quarterBudgetItems}/>
                    <hr></hr>
                </>
            }
            {this.props.monthBudgetItems.length > 0 &&
                <>
                    <h3>Monthly ({this.props.monthBudgetItems.length} items)</h3>
                    <ItemListGroupMap items={this.props.monthBudgetItems}/>
                    <hr></hr>
                </>
            }
            {this.props.fortnightBudgetItems.length > 0 &&
                <>
                    <h3>Fortnightly ({this.props.fortnightBudgetItems.length} items)</h3>
                    <ItemListGroupMap items={this.props.fortnightBudgetItems}/>
                    <hr></hr>
                </>
            }
            {this.props.weekBudgetItems.length > 0 &&
                <>
                    <h3>Weekly ({this.props.weekBudgetItems.length} items)</h3>
                    <ItemListGroupMap items={this.props.weekBudgetItems}/>
                    <hr></hr>
                </>
            }
        </div>
  </Container>
 );
}
}

export default ItemListGroups;